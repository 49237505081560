// Criteo 接入
// 此文件参考: https://marketing.criteo.com/technical-setup/assets-tags/setup-gi-direct/install?advertiserId=96881&partnerId=104558

import SparkMD5 from 'spark-md5';

const ACCOUNT = 104558;

function pushEvent(currentUser: any, ...events: { event: string, [pn: string]: any }[]) {
  if (!currentUser || !currentUser.email) {
    return;
  }
  console.debug('Criteo::pushEvent', SparkMD5.hash(currentUser.email), ...events);
  window.criteo_q = window.criteo_q || [];
  var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
  window.criteo_q.push(
      { event: "setAccount", account: ACCOUNT },
      { event: "setEmail", email: SparkMD5.hash(currentUser.email), hash_method: "md5" },
      { event: "setSiteType", type: deviceType},
      { event: "setCustomerId", id: currentUser.id },
      ...events
  );
}

function viewHome(currentUser: any){
  pushEvent(currentUser, { event: "viewHome" });
}

function viewProduct(currentUser: any, productId: string, productName: string, price?: string | number){
  pushEvent(currentUser, { event: "viewItem", item: productId, price });
}

function addToCart(currentUser: any, schema: any, product: any, quantity = 1){
  if (!schema || !product) {
    return;
  }
  pushEvent(currentUser, { event: 'addToCart', currency: 'USD', item: [
    { id: schema.goodsSchemeCode, price: schema.schemePrice, quantity }
  ] });
}

export function checkout(currentUser, order: any) {
  // console.debug('criteoPurchase', order);
  if (!order) {
    return;
  }
  pushEvent(currentUser, { 
    event: 'trackTransaction',
    id: order.orderId,
    currency: 'USD',
    item: order.goodsList.map(g => ({
      id: g.sku,
      price: g.paymentPrice || g.goodsPrice,
      quantity: g.quantity || 1
    }))
  });
}

export function useCriteo() {
  return {
    name: 'Criteo',
    viewHome,
    viewProduct,
    addToCart,
    checkout
  }
}