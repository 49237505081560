// 主要广告渠道接入文件，内部会进行不同平台的分发处理
import { useMainStore } from "@/store/";
import { useAdsTikTok } from "./ads/adsTikTok";
import { useCriteo } from "./ads/adsCriteo";
import { useAdsFacekbook } from "./ads/adsFacebook";
import { useAdsGoogle } from "./ads/adsGoogle";
import { useAdsBing } from "./ads/adsBing";

interface AdsProvider {
  /** 提供程序名称 */
  name: string,
  [pn: string]: any,
}

type Events = 'viewHome' | 'viewProduct' | 'addToCart' | 'placeOrder' | 'checkout';

const placeOrderIdList = [] as any[];

/**
 * 获取全局广告渠道事件集成
 */
export function useAdsMaster() {
  const adsLists = [
    useAdsTikTok(),
    useCriteo(),
    useAdsFacekbook(),
    useAdsGoogle(),
    useAdsBing(),
  ] as AdsProvider[];

  function useCurrentUser() {
    const mainStore = useMainStore();
    return mainStore?.currentUser;
  }

  function eachAds(funcName: Events, ...args: any[]) {
    const currentUser = useCurrentUser();
    console.debug('adsMaster:eachAds', funcName, currentUser, args);
    for(const ads of adsLists) {
      try {
        if (typeof ads[funcName] === 'function') {
          ads[funcName](currentUser, ...args);
        }
      } catch (e: any) {
        console.warn('ads invoke error', e, ads);
      }
    }
  }

  /**
   * 发送查看首页
   */
  function viewHome() {
    eachAds('viewHome');
  }

  /**
   * 发送查看产品信息
   * @param productId 商品唯一标识（发送到第三方的全部使用 SKU）
   * @param productName 商品显示名称
   * @param price 商品价格
   */
  function viewProduct(productId: string, productName: string, price?: number | string, productTypeName?: string) {
    eachAds('viewProduct', productId, productName, price, productTypeName);
  }

  /**
   * 发送产品添加到购物车
   * @param schema SKU 信息
   * @param product 上级商品信息
   * @param quantity 添加数量
   */
  function addToCart(schema: any, product: any, quantity = 1) {
    eachAds('addToCart', schema, product, quantity);
  }

  /**
   * 发送下单
   * @param order 订单信息
   */
  function placeOrder(order: any) {
    // 重复处理判断，下单只发一次通知
    if (!order || placeOrderIdList.includes(order.id)) {
      return;
    }
    placeOrderIdList.push(order.id);
    eachAds('placeOrder', order);
  }

  /**
   * 发送结算
   * @param order 订单信息
   */
  function checkout(order: any) {
    eachAds('checkout', order);
  }

  return {
    viewHome,
    viewProduct,
    addToCart,
    placeOrder,
    checkout
  }
}